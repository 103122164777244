<template>
    <icon-base :width="size + 'px'" :fill="color" viewBox="20 0 250 250">
        <path d="M132.128,249.741v-86.697c4.822-0.023,8.724-3.935,8.724-8.761v-9.083h0.203
            c17.451,0,31.599-14.147,31.599-31.599V65.273c0.001-5.947-4.821-10.767-10.768-10.767h-2.812V7.863
            c0-4.199-3.405-7.604-7.604-7.604c-4.199,0-7.603,3.405-7.603,7.604v46.643h-12.496h-3.559h-21.679V7.863
            c0-4.199-3.405-7.604-7.604-7.604c-4.199,0-7.603,3.405-7.603,7.604v46.643h-2.812c-5.947,0-10.768,4.82-10.768,10.767v48.328
            c0,17.361,14.003,31.446,31.33,31.591c0.09,0.002,0.178,0.007,0.268,0.007h0.001h0.203v9.083c0,4.827,3.903,8.738,8.724,8.761
            v86.697L132.128,249.741z M113.748,94.482l16.468-16.468c1.471-1.471,3.856-1.471,5.328,0c1.471,1.471,1.471,3.855,0,5.327
            L122.8,96.086h9.666c2.173,0,4.114,1.297,4.945,3.304c0.831,2.008,0.377,4.297-1.16,5.834l-16.186,16.186
            c-0.736,0.735-1.7,1.103-2.664,1.103c-0.964,0-1.928-0.368-2.664-1.103c-1.471-1.471-1.471-3.856,0-5.328l12.463-12.462h-9.666
            c-2.172,0-4.113-1.298-4.945-3.304C111.757,98.309,112.213,96.019,113.748,94.482z"/>
    </icon-base>
</template>

<script>
export default {
    name: 'iconCharger',
    components: {
        iconBase: () => import('@/common/components/iconBase.vue')
    },
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: 'var(--main-navy)'
        },
        enableBackground: {
            type: Boolean
        }
    }
}
</script>